import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login"),
  },
  {
    path: "/register",
    name: "reg",
    component: () => import("../views/register"),
  },
  {
    path: "/personal",
    name: "personal",
    component: () => import("../views/personal"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/agreement"),
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: () => import("../views/resetPassword"),
  },
];

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// 解决报错
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

export default router;
