import axios from 'axios';
import router from '../router/index'
let TOKEN_KEY = '_fertsfdserter';

axios.defaults.withCredentials = true;
let BASE_REQUEST_URL = 'https://www.hiworldchats.com/hi-api';
const service= axios.create({
    timeout: 100000,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    }
});
service.interceptors.request.use(config => {
    config.url = BASE_REQUEST_URL + config.url;
    if(!config.noToken) {
        config.headers.Authorization = 'Bearer ' + localStorage.getItem(TOKEN_KEY);
    }
    return config; 
 }, error => {
     return Promise.reject(error);
 });

 service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            let data = response.data
            return Promise.resolve(data);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if(error?.response?.status === 401) {
            // 回到登陆页面
            localStorage.clear()
            router.replace({
                path: "/login"
            })
        }
        return Promise.reject(error);
    }
);

export default service;
